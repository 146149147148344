<template>
  <div>
    <div class="ct dd">
      <h2 style="border-bottom: none;">
        <router-link to="/" tag="i">←返回</router-link>
        卡管理
      </h2>
    </div>
    <div class="ct">
      <ul>
        <li>
          <router-link to="/card/card1" tag="div">登记卡</router-link>
        </li>
      </ul>
      <ul>
        <li>
          <router-link to="/card/card2" tag="div">删除卡（用户号删除）</router-link>
        </li>
        <li>
          <router-link to="/card/card3" tag="div">删除卡（全部删除）</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>


export default {
    name: 'rootPassword',
    data() {
        return {}
    },
    created() {


    },
}
</script>

